<template>
  <gf-base>
    <gf-card title="Sales Invoices" subtitle="List of Sales Invoice">
      <template #header>
        <el-input style="width: 250px" class="mr-4" size="medium" prefix-icon="el-icon-search" placeholder="Search by name or company code ..." v-model="search"></el-input>
      </template>
      <gf-table :data="salesInvoices" :column-filter="columnFilter" @row-click="readSalesInvoice" v-loading="loading">
        <el-table-column v-if="columnFilter[0].show" label="Invoice #" min-width="120" prop="si_number">
        </el-table-column>
        <el-table-column v-if="columnFilter[1].show" label="Order #" min-width="120" prop="order.so_number">
        </el-table-column>
        <el-table-column v-if="columnFilter[2].show" label="Company Name" min-width="200" prop="customer.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[3].show" label="Paid" min-width="100" align="center">
          <template slot-scope="slot">
            <div class="circle" :class="[slot.row.is_paid ? 'circle--full' : '']"></div>
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[4].show" label="Payment Due" min-width="150">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.payment_due) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[5].show" label="Payment Term" min-width="160" prop="payment_term.name">
        </el-table-column>
        <el-table-column v-if="columnFilter[6].show" label="Total" min-width="100">
          <template slot-scope="slot">
            {{ $NumberFormat(slot.row.total) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[7].show" label="Issued At" min-width="150">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.issued_date) }}
          </template>
        </el-table-column>
        <el-table-column v-if="columnFilter[8].show" label="Last Updated" min-width="150">
          <template slot-scope="slot">
            {{ $DateFormat(slot.row.updated_at) }}
          </template>
        </el-table-column>
      </gf-table>
      <template #footer>
        <gf-pagination :pagination="pagination" @change="getInvoices"></gf-pagination>
      </template>
    </gf-card>
  </gf-base>
</template>

<style lang="scss" scoped>
.circle {
  width: 18px;
  height: 18px;
  border: 2px solid #585d64;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;

  &--half {
    background: linear-gradient(90deg, #585d64 50%, #fff 50%);
  }

  &--full {
    background: #585d64;
  }
}
</style>

<script>
// Services
import SalesInvoiceService from '@/services/v1/Invoice'

// Lodash
import { debounce } from 'lodash'

export default {
  data () {
    return {
      loading: false,

      columnFilter: [
        { label: 'Invoice #', show: true },
        { label: 'Order #', show: true },
        { label: 'Company Name', show: true },
        { label: 'Paid', show: true },
        { label: 'Payment Due', show: true },
        { label: 'Payment Term', show: true },
        { label: 'Total', show: true },
        { label: 'Issued At', show: true },
        { label: 'Last Updated', show: true }
      ],
      search: '',
      filter: {},

      salesInvoices: [],

      // pagination
      pagination: {
        total: 0,
        page: {
          size: 25,
          number: 1
        }
      }
    }
  },
  watch: {
    search: debounce(function (value) {
      if (value === '') {
        delete this.filter.q
      } else {
        this.filter.q = {
          like: value
        }
      }

      // Reset to page 1
      this.pagination.page.number = 1

      this.getInvoices()
    }, 400)
  },
  methods: {
    readSalesInvoice (row) {
      this.$router.push({ name: 'invoice-view', params: { id: row.order.id, iid: row.id } })
    },

    // service fetch
    async getInvoices () {
      try {
        this.loading = true
        const siService = new SalesInvoiceService()
        const result = await siService.list(this.pagination.page, null, this.filter, null)
        this.salesInvoices = result.data.rows
        if (result.data.count === 0 && result.data.rows.length !== 0) {
        } else this.pagination.total = result.data.count
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Sales Order', route: '' },
      { title: 'Invoices' }
    ])

    this.getInvoices()
  }
}
</script>
